// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.linkResolver = function linkResolver(doc) {
  // Route for blog posts
  if (doc.type === 'post') {
    return '/news/' + doc.uid;
  }

  if (doc.type === 'page') {
    return '/' + doc.uid;
  }

  if (doc.type === 'coach') {
    return '/coaches';
  }

  if (doc.type === 'class') {
    return '/classes';
  }

  if (doc.type === 'venue') {
    return '/classes';
  }

  if (doc.type === 'discipline') {
    return '/disciplines/' + doc.uid;
  }

  if (doc.type === 'policy') {
    return '/policies/' + doc.uid;
  }

  if (doc.type === 'link') {
    return doc.slug;
  }

  // Homepage route fallback
  return '/';
}
