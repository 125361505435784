import React from 'react'
import { Link, graphql } from "gatsby"
import { Date } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import Layout from '../components/layouts'

// Query for the posts content in Prismic
export const query = graphql`
{
  allPrismicPolicy(sort: {fields: data___last_reviewed_at, order: DESC}){
    edges{
      node{
        id
        uid
        type
        data {
          title {
            html
            text
            raw
          }
          last_reviewed_at
        }
      }
    }
  }
}
`

// Policy list details
const Policy = ({ policy }) => {
  // Store and format the policy last review date
  let lastReviewedAt = Date(policy.data.last_reviewed_at);
  lastReviewedAt = lastReviewedAt ? 
    new Intl.DateTimeFormat('en-US', {
      month: 'short', 
      day: '2-digit', 
      year: 'numeric'
    }).format(lastReviewedAt) :
    '';
  
  return (
    <div className="policy-summary" key={ policy.id } >
      <h4>
        {/* We render a link to a particular policy using the linkResolver for the url and its title */}
        <Link to={ linkResolver(policy) }>
          { policy.data.title.text }
        </Link>
        {lastReviewedAt && (
          <span className="blog-policy-meta">
            &nbsp; (last reviewed at: <time>{ lastReviewedAt }</time>)
          </span>
        )}
      </h4>
    </div>
  );
}

export default ({ data }) => {
  // Define the Policy content returned from Prismic
  const policies = data.allPrismicPolicy.edges;

  return(
    <Layout title="Policies">
      <div class="pure-g">
        <div class="pure-u-lg-3-5 pure-u-1-1 main">
          <h1>Policies</h1>
          <p>Below is a list of policies for the club.</p>
          {policies.map((policy) => {
            return <Policy policy={ policy.node } key={ policy.node.id }/>
          })}
        </div>
      </div>
    </Layout>
  )
}
